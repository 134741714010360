(function($) {
  $.fn.animated = function(inEffect, offset) {
    $(this).each(function() {
      let ths = $(this);
      ths.css("opacity", "0").addClass("animated").waypoint(function(dir) {
        if (dir === "down") {
          ths.addClass(inEffect).css("opacity", "1");
        }
      }, {
        offset: offset
      });

    });
  };
})(jQuery);
var keyBreakpoint = 1024;
function fancyboxThumb() {
  // console.log(window.innerWidth);
  $(".thumbnail-slider__image").on("click", function(e){
    if(window.innerWidth > keyBreakpoint || window.innerWidth < 568){
      e.preventDefault();
    }
  });

  // handle fancybox
  $(".thumbnail-slider__image").fancybox();
}
$(window).on('load', function() {
  //  100vh problem iOS fixing
  window.viewportUnitsBuggyfill.init();

  if ($(window).width() > 568) {

    $('#main-no-scroll').addClass('prevent-scroll');

    $('#main-slider').on('init', function(event, slick){
      $('.animated').addClass('activate fadeIn');
    });

    // Main slider on index page
    $('#main-slider').slick({
      arrows: false,
      infinite: false,
      speed: 1000,
      cssEase: 'ease-in-out',
      draggable: false,
    });

    $('#main-slider').on('afterChange', function(event, slick, currentSlide) {
      $('.animated').removeClass('off');
      $('.animated').addClass('activate fadeIn');
    });

  $('#main-slider').on('beforeChange', function(event, slick, currentSlide) {
    $('.animated').removeClass('activate fadeIn');
    $('.animated').addClass('off');
  });

  var parallaxAnimate = function(el, currentSlide, nextSlide){
        // console.log("parallax animate");

        var increment = "+";

         if (currentSlide > nextSlide) {
             increment = "-";
         }

         $(el).find(".top-slider__big-img:visible").animate({
             left: increment + "=15"
         }, 400, function () {
             // Animation complete.
         });
    };



     // On edge hit
     $('#main-slider').on('mousemove', function (e) {
        //console.log('mousemove', e);
     });


     // On breakpoint event
     $('#main-slider').on('breakpoint', function (event, slick, breakpoint) {
         // console.log("breakpoint", breakpoint);
     });

     // On swipe event
     $('#main-slider').on('swipe', function (event, slick, direction) {
         // console.log(direction);

     });

     // On edge hit
     $('#main-slider').on('edge', function (event, slick, direction) {
        // console.log('edge was hit')
     });




     // On after slide change
     $('#main-slider').on('afterChange', function (event, slick, currentSlide) {
         // console.log("afterChange");
         //parallaxAnimate(this, currentSlide, nextSlide);
     });

     // On before slide change
     $('#main-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        // console.log("beforeChange");
         //console.log(nextSlide);
         parallaxAnimate(this, currentSlide, nextSlide);
     });

    // Fiddle me this...
    // Event to initiate drag, include touchstart events
    $('#main-slider').on('mousedown', function(e){

     // Drag start logic
     // ...
     // console.log('drag has started');

     // Event to end drag, may want to include touchend events
     $(this).one('mouseup', function(e){

      $(this).off('mousemove');
      // Drag stop logic
      // ...

      // console.log('drag has stopped');

     }).on('mousemove', function(e){

      // Logic for dragging, can get mouse position
      // will probably want to throttle
      // possibly include touchmove events also
      // console.log('drag move', e.pageX);

     });

    });

    // Mouse wheel plugin enable
    let sliderMoving = false;
    $('#main-slider').mousewheel(function(e) {
      e.preventDefault();
      if (!sliderMoving) {
        sliderMoving = true;
        setTimeout(() => sliderMoving = false, 1300);
        if (e.deltaY < 0) {
          $('#main-slider').slick('slickNext');
        } else {
          $('#main-slider').slick('slickPrev');
        }
      }
    });

    // Slider progress bar
    $('#main-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      if (slick.$slider[0].id == 'main-slider') {
        const width = $(window).width();
        const sliderWidth = parseInt($('.slider-progress-bar').css('width'));
        const menuWidth = parseInt($('.menu-left').css('width'));
        const slideCount = slick.slideCount;
        const newDeltaWidth = Math.round((width - menuWidth) / slideCount);
        let slide = $('.slider-progress-bar').data('slide');
        if (nextSlide - currentSlide > 0) {
          $('.slider-progress-bar').css('width', sliderWidth + newDeltaWidth);
          slide += 1;
          $('.slider-progress-bar').data('slide', slide);
        }
        if (nextSlide - currentSlide < 0) {
          $('.slider-progress-bar').css('width', sliderWidth - newDeltaWidth);
          slide -= 1;
          $('.slider-progress-bar').data('slide', slide);
        }
      }
    });
  }



  // Thumbnail slider on secondary pages
  $('.thumbnail-slider').slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    adaptiveHeight: true,
    speed: 800,
    arrows: false,
    draggable: false,
    swipe: false,
    vertical: true,
    verticalSwiping: true,
    responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 1,
        slidesPerRow: 3,
        rows: 2,
        vertical: false,
        verticalSwiping: false
      }
    },
    {
      breakpoint: 568,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false
      }
    }
    ]
  });



  // thumbnail slider sync with big picture
  function setThumbnails(params) {
    $(params.thumbClass).on('click', '.slick-slide', function(e) {
      const parentId = $(this).closest(params.thumbClass).attr('id').slice(-1);
      const curSlide = parseInt(this.dataset.slickIndex) + 1;
      const video = $(this).find('.thumbnail-slider__image').attr("data-video");
      $(`#big-pic-${parentId}`).css({
        backgroundImage: `url(img/${params.path}${params.fileName}${curSlide}.${params.sufix})`
      });
      $(`#fullscreen-pic-${parentId}`).attr("href", `img/${params.path}${params.fileName}${curSlide}.${params.sufix}`);
      $(`#mobile-big-pic-${parentId}`).find('img').attr("src", `img/${params.path}${params.fileName}${curSlide}.${params.sufix}`);
      $(`#mobile-big-pic-${parentId}`).find('.fancy').attr("href", `img/${params.path}${params.fileName}${curSlide}.${params.sufix}`);
      $(`#video-${parentId},#video-mobile-${parentId}`).attr("href", "");
        $(`#video-${parentId},#video-mobile-${parentId}`).attr("data-video", "");
      if (video) {
        $(`#video-${parentId},#video-mobile-${parentId}`).attr("href", `https://youtu.be/${video}`);
        $(`#video-${parentId},#video-mobile-${parentId}`).attr("data-video", video);
        $(`#video-mobile-${parentId}`).show();
      } else {
        $(`#video-mobile-${parentId}`).hide();
      }
      $(this).siblings().removeClass('slick-selected');
      $(this).addClass('slick-selected');
    });
  }

  setThumbnails({
    thumbClass: '#thumbnail-slider-1',
    path: 'awarddress/',
    fileName: 'awarddress-slider-',
    sufix: 'jpg'
  });

  setThumbnails({
    thumbClass: '#thumbnail-slider-2',
    path: 'bridedress/',
    fileName: 'bridedress-slider-',
    sufix: 'jpg'
  });



  setThumbnails({
    thumbClass: '#thumbnail-slider-3',
    path: 'coctail/',
    fileName: 'coctail-slider-',
    sufix: 'jpg'
  });

  setThumbnails({
    thumbClass: '#thumbnail-slider-4',
    path: 'accessories/',
    fileName: 'accessories-slider-',
    sufix: 'jpg'
  });

  // Sliders on dresses pages
  $('.dresses__slider').slick({
    slidesToShow: 6,
    slidesToScroll: 6,
    adaptiveHeight: true,
    speed: 800,
    arrows: false,
    draggable: false
  });

  setThumbnails({
    thumbClass: '#slider-dresses-1',
    path: 'awarddress/',
    fileName: 'awarddress-page-slider-',
    sufix: 'jpg'
  });


  function recalcProgressBar() {
    const width = $(window).width();
    const menuWidth = parseInt($('.menu-left').css('width'));
    const slideCount = $('#main-slider').slick("getSlick").slideCount;
    const slide = $('.slider-progress-bar').data('slide');
    const newWidth = Math.round((width - menuWidth) / slideCount * slide);
    $('.slider-progress-bar').css('width', newWidth);
  }

  // on resize
  $(window).on('resize', function() {
    if ($(window).width() > 450) {
      recalcProgressBar();
    }
  });


  // fullsize version main menu handler
  $('#menu-toggle').on('click', function() {
    $('.open-menu').toggleClass('open-menu--open');
    $(this).toggleClass('hamburger--open');
    $('body').toggleClass('prevent-scroll');
  });

  $(document).on('click', function(e) {
    if($('.open-menu').hasClass('open-menu--open')) {
      if(!(($(e.target).parents('.open-menu').length !== 0) ||
          $(e.target).parents('.menu-left').length !== 0 ||
          $(e.target).hasClass('open-menu') ||
          $(e.target).hasClass('menu-left'))) {
        $('.open-menu').removeClass('open-menu--open');
        $('#menu-toggle').removeClass('hamburger--open');
        $('body').removeClass('prevent-scroll');
      }
    }
  });

  // mobile version main menu handler
  $('#mobile-menu-toggle').on('click', function() {
    $('.mobile-menu-full').toggleClass('mobile-menu-full--open');
    $(this).toggleClass('hamburger--mobile-open');
    $('body').toggleClass('prevent-scroll');
  });



  $('.top-slider__thumbnail').each(function(index, elem) {
    let imgIndex = $(elem).data('img');
    $(elem).css({
      backgroundImage: 'url(img/awarddress/awarddress-slider-1.jpg)'
    });
  });

  $('.top-slider__thumbnail').click(function(e) {
    // Узнаем номер нажатой картинки
    let id = $(e.target).data('img');

    // Присваиваем background-image
    // большому блоку с картинкой
    $(".top-slider__big-img").css({
      backgroundImage: 'url(img/awarddress/awarddress-slider-1.jpg)'
    });
  });


  if ($(window).width() > 768) {
    // Анимация при скроллинге DRESSES
    $('.series__picture-img').animated('fadeInLeft');
    $('.series__sub-header').animated('fadeInDown', '120%');
    $('.series__main-header').animated('fadeInRight', '120%');
    $('.series__text').animated('fadeInRight', '120%');
    $('.series__button').animated('fadeInUp', '120%');

    $('.description__picture-img').animated('fadeInUp', '55%');
    $('.description__header').animated('fadeInUp', '95%');
    $('.description__text').animated('fadeIn', '50%');

    $('.wantadress__picture-img').animated('fadeIn', '70%');
    $('.wantadress__text-1').animated('fadeInDown', '90%');
    $('.wantadress__options').animated('fadeInDown', '100%');
    $('.wantadress__button').animated('fadeInDown', '110%');
    $('.wantadress__volifert').animated('fadeInDown', '115%');

    $('.dresses').animated('fadeInUp', '90%');

    // Анимация при скроллинге about
    $('.designer__pic-img--1').animated('fadeInLeft');
    $('.designer__sub-header--1').animated('fadeInRight', '120%');
    $('.designer__main-header--1').animated('fadeInRight', '120%');
    $('.designer__text--1').animated('fadeInRight', '120%');

    $('.bio__volifert').animated('fadeInUp', '95%');
    $('.bio__picture-img').animated('fadeInUp', '55%');
    $('.bio__border').animated('fadeIn', '35%');
    $('.bio__text').animated('fadeInUp', '50%');

    $('.designer__pic-img--2').animated('fadeIn', '70%');
    $('.designer__text-container--1').animated('fadeIn', '70%');
    $('.designer__text--2').animated('fadeInDown', '90%');
    $('.designer__text--3').animated('fadeInDown', '90%');

    $('.connect__pic-img').animated('fadeIn', '70%');
    $('.connect__border').animated('slideInDown', '40%');
    $('.connect__wrapper').animated('fadeIn', '110%');

    // Анимация при спроллинге contactsbutton--dress

    $('.studio-contact__sub-head').animated('fadeInDown', '120%');
    $('.studio-contact__main-header').animated('fadeInLeft', '120%');
    $('.studio-contact__pic-img').animated('fadeInRight', '120%');
    $('.studio-contact__call-us').animated('slideInLeft', '120%');
    $('.studio-contact__mail').animated('slideInLeft', '120%');
    $('.studio-contact__address').animated('slideInLeft', '120%');
    $('.google-map').animated('fadeIn', '90%');

  }

  $('.anim-not-ready').removeClass('anim-not-ready');


  // Modal window contact
  $('.button--open-modal').on('click', function() {
    $('body').addClass('prevent-scroll');
    $('.modal')
      .css("display", "flex")
      .hide()
      .fadeIn(300);
  });

  // Redirect
  $('.button--dress').on('click', function(e) {
      const location = $(this).data('link');
      window.location.href = `/${location}`;
  });

  /*
  $('.menu-left__language').on('click', function(e) {
    e.preventDefault();
    const location = window.location.pathname;
    window.location.href = `http://en.volifert.com/${location}`;
  });
  */



  // Close modal window
  $('#close-modal').on('click', function() {
    $('body').removeClass('prevent-scroll');
    $('.modal').fadeOut(100);
  });
  // Close modal click on layout
  $('.modal').on('click', function(e) {
    if ($(e.target).hasClass('modal')) {
      $('body').removeClass('prevent-scroll');
      $('.modal').fadeOut(100);
    }
  });




  // validation functions
  const validateMap = {
    name: (value) => (value.search(/^[a-zA-Zа-яА-я\s]{2,20}$/) !== -1),
    default: (value) => (value !== '')
  };

  function validator(field, value, map) {
    if (map[field]) {
      return map[field](value);
    } else {
      return map['default'](value);
    }
  }

  // Call master handler
  function proccessForm(params) {
    const par = params;
    $(`${par.button}`).on('click', function(e) {
      e.preventDefault();
      let formIsValid = true;
      if ($(this).data('from')) {
        par.from = $(this).data('from');
      }
      let request = {
        from: par.from,
        data: {}
      };
      let formFields = $(`${par.formClass}`);
      formFields.each(function() {
        let value = $(this).val();
        let field = $(this).data('field');
        if (!validator(field, value, par.validateMap)) {
          formIsValid = false;
          $(this).addClass(`not-valid`);
        } else {
          request.data[field] = value;
        }
      });
      if (formIsValid) {
        let url = par.url;
        $.ajax({
          type: "POST",
          url: url,
          data: JSON.stringify(request),
          success: function(data, status) {
            params.onSuccess(data, status);
          },
          dataType: 'json'
        });
      }
    });
  }


  proccessForm({
    button: '#feedback-send',
    from: window.location.href,
    formClass: '.modal .modal__text-input',
    validateMap,
    url: "/api/callback.php",
    onSuccess: function(data, status) {
      $('.modal__form').fadeOut(300, function() {
        if(data.answer == 'success') {
          $('.modal__success')
            .css("display", "flex")
            .hide()
            .fadeIn(300);
        } else {
          $('.modal__failed')
            .css("display", "flex")
            .hide()
            .fadeIn(300);
        }
      });

    }
  });


  $('.modal .modal__text-input').on('focus', function() {
      $(this).removeClass('not-valid');
  });

  fancyboxThumb();

});


$(window).on('resize orientationchange', function() {
  $('.thumbnail-slider').slick('resize');
  fancyboxThumb();
});